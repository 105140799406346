<template>
	<div id="tu-account">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<el-tabs v-model="tabName" tab-position="top" :stretch="stretch" class="content">
					<el-tab-pane label="Personal Details" name="personal">
						<PersonalDetail @tabChange="onChangeEvent" />
					</el-tab-pane>
					<el-tab-pane label="Identification" name="identification">
						<Identification @tabChange="onChangeEvent" />
					</el-tab-pane>
					<el-tab-pane label="References" :disabled="true" name="references">
						
					</el-tab-pane>
					<el-tab-pane label="Payment Details" name="payment">
						<PaymentDetail @tabChange="onChangeEvent" />
					</el-tab-pane>
				</el-tabs>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import PersonalDetail from './components/personal'
import Identification from './components/identification'
import PaymentDetail from './components/payment'
export default {
	name: "tu-account",
	components: {
		SecondMenu,
		PersonalDetail,
		Identification,
		PaymentDetail
	},
	data() {
		return {
			stretch: true,
			tabName: 'personal'
		}
	},
	methods: {
		onChangeEvent(value) {
			this.tabName = value || 'personal';
		}
	}
}
</script>
<style lang="less">
#tu-account {
	.content {
		background: var(--el-color-white);
		padding: 15px;
	}
}
</style>