<template>
	<div id="tu-identification">
		<el-row :gutter="20">
			<div class="tips">
				
			</div>
			<el-col :span="16">
				<el-form ref="form" :model="form" :rules="rules" label-width="120px" label-position="top">
					<el-form-item label="Legal Name (required)" required>
						<el-row :gutter="20">
							<el-col :span="12">
				        <el-form-item prop="first_name">
				        	<el-input v-model="form.first_name" placeholder="First Name"></el-input>
				        </el-form-item>
				      </el-col>
				      <el-col :span="12">
				      	<el-form-item prop="last_name">
				        	<el-input v-model="form.last_name" placeholder="Last Name"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
			    </el-form-item>
			    <el-form-item label="Date of Birth (required)" required>
			    	<el-row :gutter="20">
				      <el-col :span="8">
				      	<el-form-item prop="day">
				        	<el-input v-model="form.day" placeholder="day"></el-input>
				        </el-form-item>
				      </el-col>
				      <el-col :span="8">
				      	<el-form-item prop="month">
				        	<el-input v-model="form.month" placeholder="month"></el-input>
				        </el-form-item>
				      </el-col>
				      <el-col :span="8">
				      	<el-form-item prop="year">
				        	<el-input v-model="form.year" placeholder="year"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
			    </el-form-item>
			    <el-form-item label="Billing Address (required)" required>
			    	<el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="street">
					        <el-input v-model="form.street" placeholder="street"></el-input>
					      </el-form-item>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="town">
				        	<el-input v-model="form.town" placeholder="town"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="county">
				        	<el-input v-model="form.county" placeholder="county"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="postcode">
				        	<el-input v-model="form.postcode" placeholder="postcode"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
			    </el-form-item>
			    <div class="detail">
			    	<h4>Identification Document</h4>
			    	<h4>Do you have a UK driving license with an address matching your billing address?</h4>
			    	<div>(Please select 'No' if you don't have a UK driving license)</div>
			    	<div style="margin-top: 25px;">
			    		<el-row :gutter="20">
			    			<!-- danger -->
					      <el-col :span="12">
					      	<el-button type="danger" v-if="form.id_type==1" style="width: 100%">Yes</el-button>
					        <el-button type="info" style="width: 100%" v-else @click="form.id_type=1">Yes</el-button>
					      </el-col>
					      <el-col :span="12">
					        <el-button type="danger" v-if="form.id_type==2" style="width: 100%">No</el-button>
					        <el-button type="info" style="width: 100%" v-else @click="form.id_type=2">No</el-button>
					      </el-col>
					    </el-row>
			    	</div>
			    	<div class="detail-info" v-if="form.id_type>0">
							<div class="tu-mt-12">Upload the following document</div>
							<div class="tu-mt-12" v-if="form.id_type==1">Photo of your driving license (Front side)</div>
							<div class="tu-mt-12" v-if="form.id_type==2">1. Photo of your driving license (Front side) or Passport (Photo page)</div>
							<div class="tu-mt-12">
							  <TuUpload ref="idPicture" :drag="true" uAction="teacher" showType="drag" @success="onImageIdSuccess" />
							</div>
							<div class="tu-mt-12" v-if="form.id_type == 1">
								<el-button type="primary" style="width: 100%" @click="onSubmit">Save</el-button>
								<div v-if="$store.getters.userType==2 && $store.getters.userInfo.is_pass != 1" style="margin-top: 8px;">
									<el-button style="width: 100%" type="danger" @click="nextStepProgess">
										Save & Next step
									</el-button>
								</div>
							</div>
							<h4>Document requirements</h4>
							<div class="tu-mt-12">
								<ul style="list-style: disc;padding: revert;font-size: 1rem;" class="mt-2">
                  <li class="p-1" v-if="form.id_type==1">UK driving license only</li>
                  <li class="p-1" v-if="form.id_type==2">CANNOT accept BRP cards</li>
                  <li class="p-1">Must be in date</li>
                  <li class="p-1">Photocopies not accepted</li>
                  <li class="p-1">Must be in colour</li>
                  <li class="p-1">Maximum allowed size: 4MB</li>
                  <li class="p-1">Allowed formats: JPG, JPEG, or PNG</li>
                </ul>
                <div>If you have a scanned copy such as a PDF convert it to a JPG using <a href="http://image.online-convert.com/convert-to-jpg" target="_blank">this free service</a></div>
							</div>
							<div class="tu-mt-12" v-if="form.id_type==2">2. Proof of Address</div>
							<div class="tu-mt-12" v-if="form.id_type==2">
								<TuUpload ref="otherPicture" :drag="true" uAction="teacher" showType="drag" @success="onImageOtherSuccess" />
							</div>
							<div class="tu-mt-12" v-if="form.id_type == 2">
								<el-button type="primary" style="width: 100%" @click="onSubmit">Save</el-button>
								<div v-if="$store.getters.userType==2 && $store.getters.userInfo.is_pass != 1" style="margin-top: 8px;">
									<el-button style="width: 100%" type="danger" @click="nextStepProgess">
										Save & Next step
									</el-button>
								</div>
							</div>

							<div class="tu-mt-12" v-if="form.id_type == 2">
								<el-row :gutter="20">
									<el-col :span="12">
										<div style="font-size: 16px;"><strong>Accepted</strong></div>
		                <ul style="list-style: disc;padding: revert;font-size: 1rem;" class="mt-2">
		                  <li class="p-1">Utility bill</li>
		                  <li class="p-1">Bank statement</li>
		                  <li class="p-1">Government letter</li>
		                </ul>
									</el-col>
									<el-col :span="12">
										<div style="font-size: 16px;"><strong>Not accepted</strong></div>
		                <ul style="list-style: disc;padding: revert;font-size: 1rem;" class="mt-2">
		                  <li class="p-1">Tenancy agreement or lease</li>
                      <li class="p-1">Phone bill</li>
                      <li class="p-1">Private insurance bill</li>
		                </ul>
									</el-col>
								</el-row>
								<el-row :gutter="20">
									<el-col :span="24">
										<div style="font-size: 16px;"><strong>Proof of address requirements</strong></div>
		                <ul style="list-style: disc;padding: revert;font-size: 1rem;" class="mt-2">
		                  <li class="p-1">Dated within the last 6 months</li>
                      <li class="p-1">Full name and address</li>
                      <li class="p-1">Clear and readable</li>
                      <li class="p-1">Allowed formats: JPG, JPEG, PNG, or PDF</li>
		                </ul>
									</el-col>
								</el-row>
							</div>

							<h4>ID upload guidelines</h4>
							<div class="tu-mt-12">
								<el-image
									style="width: 100%; height: 100%"
									:src="require('@/assets/driving_licence.png')"
									fit="fill"
								></el-image>
							</div>
						</div>  	
			    </div>
			  </el-form>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'
import TuUpload from '@/components/TuUpload'

export default {
	name: "tu-identification",
	props: {
		
	},
	components: {
		TuUpload
	},
	data() {
		return {
			form: {
        first_name: null,
        last_name: null,
        day: null,
        month: null,
        year: null,
        street: null,
        town: null,
        county: null,
        postcode: null,
        id_picture: null,
        other_picture: null,
        id_type: 0
      },
      rules: {
      	first_name: [
      		{
      			required: true,
      			message: "Please input First Name",
      			trigger: "blur"
      		},
          {
            min: 2,
            max: 15,
            message: 'Length should be 2 to 15',
            trigger: 'blur',
          }
      	],
      	last_name: [
      		{
      			required: true,
      			message: "Please input Last Name",
      			trigger: "blur"
      		},
          {
            min: 2,
            max: 15,
            message: 'Length should be 2 to 15',
            trigger: 'blur',
          }
				],
				day: [
					{
						required: true,
						// type: 'number',
						message: "Please input Day",
						trigger: "blur"
					}
				],
				month: [
					{
						required: true,
						// type: 'number',
						message: "Please input Month",
						trigger: "blur"
					}
				],
				year: [
					{
						required: true,
						// type: 'number',
						message: "Please input Year",
						trigger: "blur"
					}
				],
				street: [
					{
						required: true,
						message: "Please input Street",
						trigger: "blur"
					}
				],
				town: [
					{
						required: true,
						message: "Please input Town",
						trigger: "blur"
					}
				],
				county: [
					{
						required: true,
						message: "Please input County",
						trigger: "blur"
					}
				],
				postcode: [
					{
						required: true,
						message: "Please input Postcode",
						trigger: "blur"
					}
				]
      }
		}
	},
	created() {
		this.onGetIdentificationInfo()
	},
	methods: {
		onGetIdentificationInfo() {
			TeacherApi.identification("GET").then((res) => {
				this.form.first_name = res.data.first_name || null;
				this.form.last_name = res.data.last_name || null;
				this.form.day = res.data.day || null;
				this.form.month = res.data.month || null;
				this.form.year = res.data.year || null;
				this.form.street = res.data.street || null;
				this.form.town = res.data.town || null;
				this.form.county = res.data.county || null;
				this.form.postcode = res.data.postcode || null;
			});
		},
		onImageIdSuccess(value) {
			this.form.id_picture = value.code;
		},
		onImageOtherSuccess(value) {
			this.form.other_picture = value.code;
		},
		onSubmit(next) {
			if(!next) {
				next = false
			}			
			this.$refs.form.validate((valid) => {
				if(valid) {
					if(!this.form.id_picture) {
						this.$message.error(this.$t('Uploading'));
						return false;
					}
					if(this.form.id_type == 2) {
						if(!this.form.other_picture) {
							this.$message.error(this.$t('Uploading'));
							return false;
						}
					}
					TeacherApi.identification("POST", this.form).then((res) => {
		      	this.$message.success(this.$t('Save Success'));
		      	if(next) {
							this.$emit('tabChange', 'payment');
						}
					});
				}
			});			
    },
    nextStepProgess() {
    	this.onSubmit(true)
    }
	}
}
</script>
<style lang="less">
#tu-identification {
	text-align: left;
	padding: 15px;
  // border: 1px solid var(--el-border-color-extra-light);

  .tips {
  	padding: 10px 0;
    line-height: 1.5;
    font-size: 14px;
    color: var(--el-color-primary);
  }

  .el-form-item {
  	.el-form-item__label {
  		padding: 0;
  		font-size: initial;
			font-weight: 600;
  	}
  }

  .el-row {
	  margin-bottom: 20px;
	  &:last-child {
	    margin-bottom: 0;
	  }
	}

	.detail {

		.detail-info {

			.tu-mt-12 {
				margin-top: 12px;
			}

			.p-1 {
				padding: 0.45rem !important;
			}

			#tu-upload {
				.el-upload {
					display: block;

					.el-upload-dragger {
						width: 100%;
					}
				}
			}
		}
	}
}
</style>