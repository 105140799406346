<template>
	<div id="tu-personal">
		<el-row :gutter="20">
			<div class="tips">
				
			</div>
			<el-col :span="16">
				<el-form ref="form" :model="form" label-width="120px" label-position="top">
			    <el-form-item label="Name">
			    	<el-row :gutter="20">
				      <el-col :span="12">
				        <el-input v-model="form.first_name" placeholder="First Name"></el-input>
				      </el-col>
				      <el-col :span="12">
				        <el-input v-model="form.last_name" placeholder="Last Name"></el-input>
				      </el-col>
				    </el-row>
			    </el-form-item>
			    <el-form-item label="Contact">
			    	<el-row :gutter="20">
				      <el-col :span="12">
				        <el-input v-model="form.email" placeholder="Email" disabled></el-input>
				      </el-col>
				      <el-col :span="12">
				        <el-input v-model="form.mobile" placeholder="Mobile" disabled></el-input>
				      </el-col>
				    </el-row>
			    </el-form-item>
			    <el-form-item label="Address">
			    	<el-row :gutter="20">
				      <el-col :span="24">
				        <el-input v-model="form.street" placeholder="street"></el-input>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				        <el-input v-model="form.town" placeholder="town"></el-input>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				        <el-input v-model="form.county" placeholder="county"></el-input>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				        <el-input v-model="form.postcode" placeholder="postcode"></el-input>
				      </el-col>
				    </el-row>
			    </el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">{{ $t('Save') }}</el-button>
						<el-button v-if="$store.getters.userType==2 && $store.getters.userInfo.is_pass != 1" type="danger" @click="nextStepProgess">
							{{ $t('Save') }} & {{ $t('next-step') }}
						</el-button>
					</el-form-item>
			  </el-form>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import TeacherApi from '@/api/teacher'
import { mapActions, mapGetters } from 'vuex'
export default {
	name: "tu-personal",
	props: {
		
	},
	data() {
		return {
			form: {
        first_name: null,
        last_name: null,
        email: null,
        mobile: null,
        street: null,
        town: null,
        county: null,
        postcode: null,
      },
		}
	},
	created() {
		this.handlePersonal()
	},
	computed: {
		...mapGetters([
			'userInfo'
		])
	},
	methods: {
		handlePersonal() {
			this.$store.dispatch('GetUserInfo')

			if(!!this.userInfo) {
				this.form.first_name = this.userInfo.first_name ? this.userInfo.first_name : '';
				this.form.last_name = this.userInfo.last_name ? this.userInfo.last_name : '';
				this.form.mobile = this.userInfo.mobile ? this.userInfo.mobile : '';
				this.form.email = this.userInfo.email ? this.userInfo.email : '';
				this.form.street = this.userInfo.street ? this.userInfo.street : '';
				this.form.town = this.userInfo.town ? this.userInfo.town : '';
				this.form.county = this.userInfo.county ? this.userInfo.county : '';
				this.form.postcode = this.userInfo.postcode ? this.userInfo.postcode : '';
			}
		},
		onSubmit(next) {
			if(!next) {
				next = false
			}
      TeacherApi.store(this.form).then((res) => {
      	this.$store.dispatch('GetUserInfo')
      	this.$message.success(this.$t('Save Success'));
				if(next) {
					this.$emit('tabChange', 'identification');
				}
      });
    },
    nextStepProgess() {
    	this.onSubmit(true);
    }
	}
}
</script>
<style lang="less">
#tu-personal {
	text-align: left;
	padding: 15px;
  // border: 1px solid var(--el-border-color-extra-light);

  .tips {
  	padding: 10px 0;
    line-height: 1.5;
    font-size: 14px;
    color: var(--el-color-primary);
  }

	.el-form-item {
		.el-form-item__label {
			padding: 0;
			font-size: initial;
			font-weight: 600;
		}
	}

  .el-row {
	  margin-bottom: 20px;
	  &:last-child {
	    margin-bottom: 0;
	  }
	}
}
</style>