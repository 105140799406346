<template>
	<div id="tu-payment">
		<el-row :gutter="20">
			<el-col :span="16">
				<div class="tips">
					This needs to be a UK bank account with a UK Mainland billing address. You can change these details at any point, by entering your new details in the fields below.
				</div>
				<el-form ref="form" :model="form" :rules="rules" label-width="120px" label-position="top">
					<el-form-item label="Bank Account" required v-if="userInfo.currency_value == 2">
						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item prop="account_number">
				        	<el-input v-model="form.account_number" placeholder="Account Number: XXX 5790"></el-input>
				        </el-form-item>
				      </el-col>
				      <el-col :span="12">
				      	<el-form-item prop="sort_code">
				        	<el-input v-model="form.sort_code" placeholder="Sort Code: XXX"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
			    </el-form-item>
			    <el-form-item label="Billing Address" required v-if="userInfo.currency_value == 2">
			    	<el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="street">
				        	<el-input v-model="form.street" placeholder="street"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="town">
				        	<el-input v-model="form.town" placeholder="town"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="county">
				        	<el-input v-model="form.county" placeholder="county"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="postcode">
				        	<el-input v-model="form.postcode" placeholder="postcode"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
			    </el-form-item>

			    <el-form-item label="银行账号" required v-if="userInfo.currency_value == 1">
			    	<el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="account_number">
				        	<el-input v-model="form.account_number" placeholder="银行账号"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
				  </el-form-item>
				 	<el-form-item label="开户行" required v-if="userInfo.currency_value == 1">
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="bank">
				        	<el-input v-model="form.pay_data.bank" placeholder="开户行"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
				  </el-form-item>
				  <el-form-item label="真实姓名" required v-if="userInfo.currency_value == 1">
				    <el-row :gutter="20">
				      <el-col :span="24">
				      	<el-form-item prop="realname">
				        	<el-input v-model="form.pay_data.realname" placeholder="真实姓名"></el-input>
				        </el-form-item>
				      </el-col>
				    </el-row>
			    </el-form-item>

			    <el-form-item>
						<el-button type="primary" @click="onSubmit">{{ $t('Save') }}</el-button>
						<el-button v-if="userType==2 && userInfo.is_pass != 1" type="danger" @click="nextStepProgess">
							{{ $t('Save') }} & {{ $t('Finish') }}
						</el-button>
					</el-form-item>
			  </el-form>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import TeacherApi from '@/api/teacher'
export default {
	name: "tu-payment",
	props: {
		
	},
	data() {
		return {
			form: {
				pay_data: {
					account_number: null,
					bank: null,
					realname: null,
				},
        account_number: null,
        sort_code: null,
        street: null,
        town: null,
        county: null,
        postcode: null,
      },
      rules: {
      	account_number: [
					{
						required: true,
						message: "Please input account number",
						trigger: "blur"
					}
      	],
      	sort_code: [
					{
						required: true,
						message: "Please input sort code",
						trigger: "blur"
					}
      	],
      	street: [
					{
						required: true,
						message: "Please input Street",
						trigger: "blur"
					}
				],
				town: [
					{
						required: true,
						message: "Please input Town",
						trigger: "blur"
					}
				],
				county: [
					{
						required: true,
						message: "Please input County",
						trigger: "blur"
					}
				],
				postcode: [
					{
						required: true,
						message: "Please input Postcode",
						trigger: "blur"
					}
				]
      }
		}
	},
	created() {
		this.onGetPaymentInfo()
	},
	computed: {
		...mapGetters([
			'userInfo', 'userType'
		])
	},
	methods: {
		onGetPaymentInfo() {
			TeacherApi.paymentdetail("GET").then((res) => {
				if(res.data) {
					this.form.street = res.data.street
					this.form.town = res.data.town
					this.form.county = res.data.county
					this.form.postcode = res.data.postcode
					if(!!res.data.pay_data && this.userInfo.currency_value == 1) {
						this.form.pay_data = res.data.pay_data;
						this.form.account_number = res.data.pay_data.account_number || '';
					}
				}
			})
		},
		onSubmit(next) {
			if(next !== true) {
				next = false;
			}
			this.$refs.form.validate((valid) => {
				if(valid) {
					if(this.userInfo.currency_value == 1) {
						this.form.pay_data.account_number = this.form.account_number;
					}
					TeacherApi.paymentdetail("POST", this.form).then((res) => {
						this.$message.success(this.$t('Save Success'));
						if(next) {
							this.$store.dispatch('GetUserInfo')
							this.$router.push('/tu/home');
						}
					})
				}
			});      
		},
		nextStepProgess() {
			this.onSubmit(true);
		}
	}
}
</script>
<style lang="less">
#tu-payment {
	text-align: left;
	padding: 15px;
  // border: 1px solid var(--el-border-color-extra-light);

  .tips {
  	padding: 10px 0;
    line-height: 1.5;
    font-size: 14px;
    color: var(--el-color-primary);
  }

  .el-form-item {
  	.el-form-item__label {
  		padding: 0;
			font-size: initial;
			font-weight: 600;
  	}
  }

  .el-row {
	  margin-bottom: 20px;
	  &:last-child {
	    margin-bottom: 0;
	  }
	}
}
</style>